import { UUID } from '@shared/interfaces/uuid.interface';
import { INCIDENT_TYPE_ENUM } from '@features/admin/constants/admin-item.constant';
import { INCIDENT_PRIORITY } from '@features/incident/constants/incident-priority.constant';
import { IName } from '@shared/interfaces/name.interface';
import { ITechniques } from '@features/admin/interfaces/technique.interface';

export interface ITechniqueRequest {
  incident: {
    id: UUID,
    address: string,
    incident_number: string,
    incident_type: INCIDENT_TYPE_ENUM,
    priority: INCIDENT_PRIORITY
  },
  id: UUID,
  technique_type: IName,
  technique_type_id: UUID,
  comments: string,
  supplied_at: string,
  operator_comments: string,
  brigade_id: string,
  created_at: string,
  result_date: string,
  incident_id: string,
  status: RequestStatusEnum,
  technique: ITechniques,
}

export interface IMaterialRequest {
  incident: {
    id: UUID,
    address: string,
    incident_number: string,
    incident_type: INCIDENT_TYPE_ENUM,
    priority: INCIDENT_PRIORITY
  },
  id: UUID,
  is_supplied: boolean,
  requested_materials: string,
  supplied_materials: string,
  operator_comments: string,
  supplied_at: string,
  result_date: string,
  comments: string,
  incident_id: string,
  brigade_id: string
  status: RequestStatusEnum
}

export enum RequestStatusEnum {
  PENDING = 'PENDING',
  SUPPLIED = 'SUPPLIED',
  CANCELLED = 'CANCELLED',
}

export interface IRequestsCount {
  status: RequestStatusEnum,
  count: number
}
