export function removeEmptyProperties(obj: { [key: string]: any }): { [key: string]: any } {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        // Recursively remove empty properties from nested objects
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          value = removeEmptyProperties(value);
        }
        return [key, value];
      })
      .filter(([_, value]) => {
        // Filter out empty values and empty objects
        return value !== undefined && value !== null && value !== '' &&
          !(Array.isArray(value) && value.length === 0) &&
          !(typeof value === 'object' && Object.keys(value).length === 0);
      })
  );
}
