import { Injectable } from '@angular/core';
import { HttpService } from '@shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsApiService extends HttpService {

  constructor() {
    super();
  }
  getRequests<T>(url: 'material' | 'technique', params) {
    return this.get<T>(`api/${url}_requests`, {params})
  }

  getRequestCount<T = any>(url: 'material' | 'technique') {
    return this.get<T>(`api/${url}_requests/find/count`)
  }

  getRequest<T>(url: 'material' | 'technique', requestId: string) {
    return this.get<T>(`api/${url}_requests/${requestId}`)
  }

  updateRequest<T>(url: 'material' | 'technique', requestId: string, data: any) {
    return this.put<T>(`api/${url}_requests/set_status/${requestId}`, data)
  }
}
