import { Injectable } from '@angular/core';
import { Observable, map, filter} from 'rxjs';
import { IArea } from '@shared/features/gist/interfaces/area.interface';
import { HttpService } from '@shared/services/http.service';
import { IRequestParams } from '@shared/interfaces/request-params.interface';
import { IncidentList, IncidentRequest, IncidentView } from '../interfaces/incident.interface';
import { Brigade } from '../interfaces/brigade.interface';
import { IPageable } from '@shared/interfaces/pageable.interface';
import { IStatusCounts } from '@features/incident/interfaces/tab.interface';
import { removeEmptyProperties } from '@shared/utils/customFilterProperties';
import { ITechniques } from '@features/admin/interfaces/technique.interface';
import { IName } from '@shared/interfaces/name.interface';

@Injectable({
  providedIn: 'root',
})
export class IncidentApiService extends HttpService {
  getAreas(
    regionId: string,
    districtId: string,
  ): Observable<IArea[]> {
    return this.get('api/areas', {params: {district_id: districtId}});
  }

  getNeighborhoods(
    param: { area_id?: string; district_id?: string },
  ): Observable<IArea[]> {
    const filtered = removeEmptyProperties(param)
    return this.get('api/neighborhoods', {params: filtered});
  }

  getBranches(): Observable<IArea[]> {
    return this.get('api/branches/find_with_districts');
  }

  getFuelType(): Observable<IName[]> {
    return this.get('api/fuel_types');
  }

  getTechniqueTypes(): Observable<IName[]> {
    return this.get('api/technique_types');
  }

  getOriginTypes(): Observable<IArea[]> {
    return this.get('api/origin-types');
  }

  getLocationTypes(): Observable<IArea[]> {
    return this.get('api/location-types');
  }

  getPlaceTypes(): Observable<IArea[]> {
    return this.get('api/place-types');
  }

  getUsers(): Observable<IName[]> {
    return this.get('api/users');
  }

  createIncident(incident: IncidentRequest): Observable<IncidentRequest> {
    return this.post('api/incidents', incident);
  }

  updateIncident(incident: IncidentRequest, id: string): Observable<IncidentRequest> {
    return this.put(`api/incidents/${id}`, incident);
  }

  getIncidents(params: IRequestParams = {}): Observable<IPageable<IncidentList[]>> {
    const searchParams = {
      ...params,
      ...params.filter
    }
    delete searchParams.filter
    return this.get('api/incidents/page', { params: searchParams });
  }

  getIncident(id: string): Observable<IncidentView> {
    return this.get(`api/incidents/${id}`);
  }

  getIncidentCounts(): Observable<IStatusCounts[]> {
    return this.get(`api/incidents/count_by_status`);
  }

  getRegionsOrDistricts(region_id?: string) {
    return this.get<any>('api/regions').pipe(
      filter(item => {
        return region_id ? item.parent_id === region_id : true
      }),
      map(w =>
        w.map(r => {
          if (!r.location) console.log(r, 'no location');
          return { ...r, center: r.location ? [r.location.lat, r.location.long] : [] } as IArea;
        })
      )
    );
  }

  getBrigades(body?: any) {
    return this.get<Brigade[]>('api/brigades', body);
  }

  getOrganizations(body?: any) {
    return this.get<IName[]>('api/organizations', body);
  }

  getActiveBrigades(params?: any) {
    return this.get<Brigade[]>('api/brigades/active', { params: params });
  }

  getSpecTechniques(url = '/find_special',params?: any) {
    return this.get<ITechniques[]>(`api/techniques${url}`, { params: params });
  }

  sendToBrigade(body) {
    const { incident_id, ...values} = body;
    return this.put(`api/incidents/send_to_branch/${incident_id}`, values)
  }

  getBrigadeIncidents(brigade_id: string) {
    return this.get('api/incidents_brigades', {params: {brigade_id}})
  }

  getIncidentTechniques(technique_id: string) {
    return this.get('api/incident_techniques', {params: {technique_id}})
  }

  assignBrigade(body) {
    const { main_incident_brigade_id: id, ...data } = body
    if(id && data.is_main) {
      return this.put(`api/incidents_brigades/${id}`, data)
    }
    return this.post('api/incidents_brigades', data)
  }

  assignSpecTechnique(body) {
    return this.post('api/incident_techniques', body)
  }

  deleteAssignedIncidentBrigade(id: string) {
    return this.delete(`api/incidents_brigades/${id}`)
  }

  deleteAssignedSpecTechnique(id: string) {
    return this.delete(`api/incident_techniques/${id}`)
  }

  brigadeCoordinates(params) {
    return this.get('api/brigades/coordinates', { params })
  }

  assignBrigadeAtWork(body) {
    const {brigade_id: id, ...params} = body
    return this.put(`api/brigades/technique/${id}`, params)
  }

  getIncidentHistory(incident_id) {
    return this.get('api/incident_histories', {params: {incident_id}})
  }

  getTechniquesWithCoordinates() {
    return this.get('api/techniques/find_all_by_user')
  }

  getBrigadeHistory<T>(params) {
    return this.get<T>('api/brigades_histories', {params})
  }
}
