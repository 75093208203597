export enum ECardStatus {
  NotProcessed = 'NotProcessed',
  Processed = 'Processed',
  InProgress = 'InProgress',
  Assigned = 'Assigned',
}

export const CARD_STATUS_DATA = {
  [ECardStatus.NotProcessed]: {
    id: ECardStatus.NotProcessed,
    name_uzl: 'Yangi',
    name_rus: 'Новый',
    name_uz: 'Янги',
    color: '#f44336',
    bgClass: `bg-[#f44336]`,
  },
  [ECardStatus.Processed]: {
    id: ECardStatus.Processed,
    name_uzl: 'Bajarilgan',
    name_rus: 'Выполненный',
    name_uz: 'Бажарилган',
    color: '#4caf50',
    bgClass: `bg-[#4caf50]`,
  },
  [ECardStatus.InProgress]: {
    id: ECardStatus.InProgress,
    name_uzl: 'Jarayonda',
    name_rus: 'В процессе',
    name_uz: 'Жараёнда',
    color: '#ffc107',
    bgClass: `bg-[#ffc107]`,
  },
  [ECardStatus.Assigned]: {
    id: ECardStatus.Assigned,
    name_uzl: 'Tayinlangan',
    name_rus: 'Назначенный',
    name_uz: 'Тайинланган',
    color: '#2196f3',
    bgClass: `bg-[#2196f3]`,
  },
};

export enum INCIDENT_STATUSES {
  CREATED = 'CREATED',
  REGISTERED = 'REGISTERED',
  ASSIGNED = 'ASSIGNED',
  ON_WAY = 'ON_WAY',
  PENDING = 'PENDING',
  IN_PROCESS = 'IN_PROCESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export const specialTechniquesStatusesMap = {
  [INCIDENT_STATUSES.CREATED]: INCIDENT_STATUSES.CREATED,
  [INCIDENT_STATUSES.REGISTERED]: INCIDENT_STATUSES.REGISTERED,
  [INCIDENT_STATUSES.ASSIGNED]: 'TECHNIQUES_ASSIGNED',
  [INCIDENT_STATUSES.ON_WAY]: INCIDENT_STATUSES.ON_WAY,
  [INCIDENT_STATUSES.PENDING]: INCIDENT_STATUSES.PENDING,
  [INCIDENT_STATUSES.IN_PROCESS]: INCIDENT_STATUSES.IN_PROCESS,
  [INCIDENT_STATUSES.COMPLETED]: INCIDENT_STATUSES.COMPLETED,
  [INCIDENT_STATUSES.CANCELLED]: INCIDENT_STATUSES.CANCELLED,
}

export const commonBadge = {
  count: 0,
    textColor: 'white',
    bgColor: '#f59e0b',
};

export const statusTabs: {
  id: string,
  name: string,
  active?: boolean,
  badge?: {
    count: number,
    textColor: string,
    bgColor: string
  }
}[] = [
  {
    id: '',
    name: 'all.incidents',
    active: true,
    badge: {
      count: 0,
      textColor: 'white',
      bgColor: '#f59e0b',
    },
  },
  {
    id: INCIDENT_STATUSES.CREATED,
    name: INCIDENT_STATUSES.CREATED,
  },
  {
    id: INCIDENT_STATUSES.REGISTERED,
    name: INCIDENT_STATUSES.REGISTERED,
  },
  {
    id: INCIDENT_STATUSES.ASSIGNED,
    name: INCIDENT_STATUSES.ASSIGNED,
  },
  {
    id: INCIDENT_STATUSES.ON_WAY,
    name: INCIDENT_STATUSES.ON_WAY,
  },
  {
    id: INCIDENT_STATUSES.IN_PROCESS,
    name: INCIDENT_STATUSES.IN_PROCESS,
  },
  {
    id: INCIDENT_STATUSES.PENDING,
    name: INCIDENT_STATUSES.PENDING,
  },
  {
    id: INCIDENT_STATUSES.CANCELLED,
    name: INCIDENT_STATUSES.CANCELLED,
  },{
    id: INCIDENT_STATUSES.COMPLETED,
    name: INCIDENT_STATUSES.COMPLETED,
  },
];

export const incidentStatusesClasses = [
  {
    id: INCIDENT_STATUSES.CREATED,
    classes: 'text-sm bg-white text-gray-900 border rounded-md px-1',
  },
  {
    id: INCIDENT_STATUSES.REGISTERED,
    classes: 'text-sm bg-gray-200 text-gray-900 border border-bg-gray-300 rounded-md px-1',
  },
  {
    id: INCIDENT_STATUSES.ASSIGNED,
    classes: 'text-sm bg-orange-100 text-orange-900 border rounded-md px-1',
  },
  {
    id: INCIDENT_STATUSES.IN_PROCESS,
    classes: 'text-sm bg-indigo-100 text-indigo-700 border border-indigo-200 rounded-md px-1',
  },
  {
    id: INCIDENT_STATUSES.PENDING,
    classes: 'text-sm bg-violet-100 text-violet-700 border border-violet-200 rounded-md px-1',
  },
  {
    id: INCIDENT_STATUSES.ON_WAY,
    classes: 'text-sm bg-green-50 text-green-500 border rounded-md px-1',
  },
  {
    id: INCIDENT_STATUSES.COMPLETED,
    classes: 'text-sm bg-green-100 text-green-700 border rounded-md px-1',
  },
  {
    id: INCIDENT_STATUSES.CANCELLED,
    classes: 'text-sm bg-orange-100 text-orange-900 border rounded-md px-1',
  },

  // request statuses
  {
    id: 'SUPPLIED',
    classes: 'text-sm bg-green-100 text-green-700 border rounded-md px-1',
  },
];
